import React from 'react';
import { useQuery, gql } from '@apollo/client';

import HandleLoading from '@hoc/handle-loading/handle-loading';
import MainLayout from '@hoc/layout';
import ContactSection from '@components/contact-section/contact-section';
import MapSection from '@components/map-section/map-section';
import BreadCrumbs from '@components/bread-crumbs/bread-crumbs';
import Seo from '@components/seo/seo';

import * as s_wrapper from '@assets/styles/wrapper.module.scss';
import * as s_text from '@assets/styles/text.module.scss';
import './wp-styles.scss';

const PAGE_QUERY = gql`
    query BlogPostsQuery($slug: String) {
        offerBy(slug: $slug) {
            content
        }
    }
`;


const PrivacyPolicy = () => {

  const { loading, error, data } = useQuery(PAGE_QUERY, {
    variables: {slug: 'leasing-na-start'}
});

  return (
    <MainLayout>

      <BreadCrumbs />

      <Seo
        title='Leasing na start dla nowych firm'
				description='Leasing i wynajem długoterminowy samochodów, maszyn i urządzeń, sprzętów budowlanych. ClickLease to najszybciej rozwijający się broker leasingu na polskim rynku. W swojej ofercie mamy aż 40 firm leasingowych. To szeroki wachlarz możliwości.'
      />

      <section className={`${s_wrapper.apply} ${s_wrapper.apply_height}`}>

        <article className={s_text.text_view}>
          <HandleLoading loading={loading} error={error}>

            <h1>Leasing na start - dla nowych firm</h1>

            <div dangerouslySetInnerHTML={{ __html: data?.offerBy.content }}>
            </div>

          </HandleLoading>

          {/* <div className={s_text.double}>
            <div>
              <p>
                Znudziła Cię praca „dla kogoś”. Wiele się nauczyłeś, wiele już wiesz i pragniesz samodzielności.
                Masz doświadczenie w wybranej branży i nie boisz się nowych wyzwań i doświadczeń? Leasing dla start up,
                dl nowych firm to coś dla Ciebie. Proste i bezproblemowe formy finansowania na oświadczenie, jedyny warunek
                to udokumentowanie wcześniejszego doświadczenia. A potem same przyjemności. Do wyboru do koloru. Maszyny,
                urządzenia, samochody osobowe, transport ciężki, dostawczaki, maszyny rolnicze. Czego tylko dusza nie zapragnie.
                A do tego same korzyści: indywidualna obsługa i pełne wsparcie na każdym etapie umowy, możliwość skorzystania z
                bezpłatnych porad, negocjacje warunków finansowania, optymalizacja podatku dochodowego oraz VAT. Nie zwlekaj dbaj
                o swój rozwój i swoje pieniądze.
              </p>
            </div>
            <div>
              <p>
                Masz pomysł na firmę, ale nie masz środków? Zakup sprzętu jest kosztowny? Masz oszczędności ale na razie wolisz ich
                nie ruszać? Boisz się ryzyka? Jeśli na którekolwiek z powyższych pytań odpowiedziałeś „Tak”, to znaczy że mamy dla Ciebie
                gotowe rozwiązanie. Leasing na strat. To to czego szukasz. Możliwość finansowania od 1-ego dnia prowadzenia działalności,
                harmonogram spłat dostosowany do Twoich potrzeb, minimalna wpłata własna, okres finansowania nawet do 84 m-cy, możliwość
                rat sezonowych, decyzja leasingowa w ciągu kilku godzin. Pamiętaj, też o korzyściach jakie niesie za sobą leasing na start.
                To możliwość wliczenia rat leasingowych do kosztów, płynność finansowa dla Twojego portfela, „poduszka bezpieczeństwa” dla Ciebie.
              </p>
            </div>
          </div>

          <div className={s_text.double}>
            <div>
              <h2>Korzyści leasingu na start, dla nowych firm</h2>

              <ul className={s_text.checkmark_list}>
                <li>Niskie wpłaty wstępne, długi okres finansowania</li>
                <li>Wygoda, oszczędność czasu (uproszczone procedury, szybki czas rozpatrywania wniosków, bez wychodzenia z domu!)</li>
                <li>Atrakcyjne pakiety ubezpieczeń rozbitych na raty</li>
                <li>Bezpieczne, proste finansowanie i możliwość rozwoju firmy, bez konieczności zwiększania kapitału potrzebnego na start</li>
                <li>Finansowanie przedmiotów nowych i używanych różnych rodzajów, w zależności od potrzeb przedsiębiorstwa (samochody, maszyny rolnicze, leśne, budowlane, sprzęty IT, wyposażenie biura, gastronomii oraz wiele innych)</li>
              </ul>
            </div>

            <div>
              <h2>Warunki leasingu na start, dla nowych firm</h2>
              <ul className={s_text.dash_list}>
                <li>Okres finansowania od 12 do 84 miesięcy</li>
                <li>Wpłata własna od 1% wartości przedmiotu leasingu</li>
                <li>Nawet od pierwszego dnia prowadzenia działalności</li>
                <li>Brak skomplikowanych formalności (m.in. brak konieczności przedstawiania zaświadczeń finansowych) oraz minimum wymaganych dokumentów (dowód osobisty oraz dokumenty rejestrowe firmy)</li>
                <li>Elastyczne płatności, dostosowane do możliwości Klienta</li>
                <li>Szybkie rozpatrywanie wniosków i decyzja leasingowa (promesa leasingowa do 3 godzin)</li>
                <li>Oferta specjalna dla osób wykonujących wolne zawody</li>
              </ul>
            </div>
          </div> */}

        </article>

      </section>

      <ContactSection />
      <MapSection />

    </MainLayout>
  );

}

export default PrivacyPolicy;
